import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

const { version } = require('../package.json');
console.log(`App version: ${version}`);

ReactDOM.render(
  <React.StrictMode>
    <App />
    <div className="version" style={{ fontSize: 'small', width: '100%', textAlign: 'left'}}><small>v{version}</small></div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
