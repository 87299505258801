import React, { useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  ImageField,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import { Form } from 'react-final-form';

import { Box, CssBaseline, Grid, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { APIService } from '../../common/api.service';
import { ATTRACTION_DOCUMENTATION, ATTRACTION_KEYWORDS } from '../attractions/documentation';
import AddAdditionalDataButton from './add-additional-data-button';
import AttractionGenerateDefaults from './attractions-generate-defaults';
import AttractionsLanguageFallback from './attractions-language-fallback';
import AttractionSimulation from './attractions-simulation';
import ToggleAdditionalDataButtons from './toggle-additional-data-buttons';

const ReactMarkdown = require('react-markdown');

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  additional: {
    margin: theme.spacing(1),
    minHeight: 'unset',
    backgroundColor: 'white',
  },
}));

const postRowStyle = (record: any) => ({
  backgroundColor: record && record.type && record.type.split('_')[0] === 'sound' ? '#efe' : 'white',
});

export const RoomShow = (props: any) => {
  const classes = useStyles();
  const [outputAttractionCheck, setOutputAttractionCheck] = useState('');
  const { record } = useShowController(props);

  const onSubmitAttractionCheck = async (value: any) => {
    try {
      const result = await APIService.attractionCheck(value.roomId);
      setOutputAttractionCheck(JSON.stringify(result, null, 2));
    } catch (e) {
      setOutputAttractionCheck('Uuups, something went wrong 🙈');
    }
  };

  const onSubmitTestNewsletter = async (value: any) => {
    await APIService.testNewsletter(value.roomId);
  };

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Race">
          <TextField source="id" />
          {props.permissions === 'superadmin' && <TextField source="tenant.name" label="Tenant" />}
          <TextField source="event_title" />
          <TextField label="Event Description" source="description" />
          <BooleanField source="isPublic" />
          <BooleanField source="rerun" />
          <NumberField source="distance" label="Distance in m or Time in s depending on race type" />
          <DateField source="startDate" />
          <DateField source="endDate" />
          {props.permissions === 'superadmin' && <TextField source="route_image" />}
          {props.permissions === 'superadmin' && <TextField source="event_logo" />}
          <TextField label="Course Description" source="intro" />
          <TextField source="country" />
          <TextField source="place" />
          {props.permissions === 'superadmin' && <TextField source="web_link" />}
          {props.permissions === 'superadmin' && <NumberField source="category_id" />}
          {props.permissions === 'superadmin' && <BooleanField source="onlyAllowWomen" label="Only allow women" />}
          {props.permissions === 'superadmin' && <TextField source="code" />}
          {props.permissions === 'superadmin' && <ImageField source="certificateImage" />}
          {props.permissions === 'superadmin' && <BooleanField source="withPersonalizedCodes" label="Race with personalized code?" />}
          {props.permissions === 'superadmin' && <BooleanField source="agegroupRanking" label="Race allowing age group ranking?" />}
          {props.permissions === 'superadmin' && (
            <BooleanField source="showRCBtn" label="Show RunningCoach Popup after the race to create a plan" />
          )}
          <TextField label="Race Type" source="raceType" />
          {props.permissions === 'superadmin' && <TextField label="Timing Type" source="timingType" />}
          {props.permissions === 'superadmin' && <TextField source="sportType" />}
          <BooleanField source="createBib" />
          <TextField source="bibFontColor" />
          <EditButton />
        </Tab>
        {props.permissions === 'superadmin' && (
          <Tab label="Newsletter">
            <TextField source="newsletterTemplateId" label="Newsletter Template ID" />
            <BooleanField source="newsletterPreSend" label="Send pre-race Newsletter (24h before race start)" fullWidth />
            {record && record.newsletterPreSend && <BooleanField source="newsletterPreAttachBib" label="Attach Bib" fullWidth />}
            <BooleanField source="newsletterPostSend" label="Send post-race Newsletter  (2h after race start)" fullWidth />
            {record && record.newsletterPostSend && (
              <BooleanField source="newsletterPostAttachCertificate" label="Attach Certificate" fullWidth />
            )}

            {/* SignupRaceMail */}
            <BooleanField source="signupRaceMail" label="Send Mail when a user signs up for the race" fullWidth />
            {record && record.signupRaceMail && (
              <BooleanField source="signupRaceMailAttachBib" label="Attach Bib to Signup Mail" fullWidth />
            )}

            {/* FinishRaceMail */}
            <BooleanField source="finishRaceMail" label="Send Mail when a user finishes the race" fullWidth />
            {record && record.finishRaceMail && (
              <BooleanField source="finishRaceMailAttachCertificate" label="Attach Certificate to Finish Mail" fullWidth />
            )}
          </Tab>
        )}
        {props.permissions === 'superadmin' && (
          <Tab label="Push">
            {/* 24h before Push */}
            <BooleanField source="pushPre24HSend" label="Send pre-race Push (24h before startDate)" fullWidth />

            {/* 2h before Push */}
            <BooleanField source="pushPre2HSend" label="Send pre-race Push (2h before startDate)" fullWidth />

            {/* Finish Push */}
            <BooleanField source="finishPush" label="Send Push when a user finishes the race" fullWidth />

            {/* Favorite Push */}
            <BooleanField source="favoritesPush" label="Send Push when a favorite of a user finishes the race" fullWidth />
          </Tab>
        )}
        <Tab label="Participations">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            label="Participation"
            reference="participation"
            target="roomId"
            source="id"
          >
            <Datagrid>
              <NumberField source="id" />
              <TextField source="user.username" />
              <TextField source="user.gender" />
              <DateField source="createdAt" />
              <TextField source="time" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Attractions">
          {props.permissions === 'superadmin' && <ReactMarkdown source={ATTRACTION_DOCUMENTATION} fullWidth></ReactMarkdown>}
          <ReactMarkdown source={ATTRACTION_KEYWORDS} fullWidth></ReactMarkdown>
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={25}
            label="Attractions"
            reference="attraction"
            target="roomId"
            source="id"
          >
            <Datagrid rowStyle={postRowStyle}>
              <NumberField label="Distance OR Seconds" source="distance" />
              <TextField source="speakString" />
              <TextField source="language" />
              <TextField source="type" />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          {props.permissions === 'superadmin' && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <AttractionGenerateDefaults {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper>
                  <AttractionsLanguageFallback {...props} />
                </Paper>
              </Grid>
            </Grid>
          )}
        </Tab>
        <Tab label="Matches">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            label="ActivityRoomMatch"
            reference="activity-room-match"
            target="roomId"
          >
            <Datagrid>
              <NumberField source="id" />
              <NumberField source="activityId" />
              <BooleanField source="used" />
              <BooleanField source="triedToParticipate" />
              <TextField source="participateError" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="VC Simulation">
          <AttractionSimulation {...props} />
        </Tab>
        {props.permissions === 'superadmin' && (
          <Tab label="Attraction Check">
            <div className={classes.root}>
              <Form
                onSubmit={onSubmitAttractionCheck}
                initialValues={{ roomId: props.id }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Box my={2}>
                      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                        Check
                      </Button>
                    </Box>
                  </form>
                )}
              />
            </div>
            <div>
              <pre>{outputAttractionCheck}</pre>
            </div>
          </Tab>
        )}
        {props.permissions === 'superadmin' && (
          <Tab label="Additional Data" path="additional-data">
            <ReferenceManyField addLabel={false} reference="roomAdditionalData" target="roomId">
              <Datagrid>
                <NumberField source="id" />
                <TextField source="name" />
                <TextField source="value" />
                <EditButton />
                <DeleteButton redirect={record?.id ? `/room/${record.id}/show/additional-data` : null} />
              </Datagrid>
            </ReferenceManyField>
            <ToggleAdditionalDataButtons />
            <AddAdditionalDataButton />
          </Tab>
        )}
        {props.permissions === 'superadmin' && (
          <Tab label="Test Newsletter">
            <div className={classes.root}>
              <CssBaseline />
              <Form
                onSubmit={onSubmitTestNewsletter}
                initialValues={{ roomId: props.id }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Box my={2}>
                      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                        Test Newsletter
                      </Button>
                    </Box>
                  </form>
                )}
              />
            </div>
            <ReactMarkdown source={outputAttractionCheck} fullWidth></ReactMarkdown>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
