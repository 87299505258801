import React from 'react';
import Button from '@material-ui/core/Button';
import { APIService } from '../../common/api.service';
import { useToasts } from 'react-toast-notifications';

const DownloadTrackButton = (props: any) => {
  const { addToast } = useToasts();
  const handleClick = async () => {
    const response = await APIService.downloadParticipationProgress(props.record.id);
    if (response) {
      const url = await response.text();
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    } else {
      addToast('File not found!', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Button variant="contained" size="small" color="primary" onClick={handleClick}>
      Download Track
    </Button>
  );
};

export default DownloadTrackButton;
