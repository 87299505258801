import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ShowButton,
  EditButton,
  Filter,
  required,
  SimpleFormIterator,
  Show,
  ArrayInput,
  BooleanField,
  DateField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  ImageField,
  TabbedShowLayout,
  Pagination,
  Tab,
  NumberInput,
  NumberField,
} from 'react-admin';
import { DataTransformations } from '../../common/http';
import { FormattedUserField } from '../users/users';
import { LOCALES } from '../../constants/locales';
import BackendFileUpload from '../../common/backend-file-upload';

interface I18nLabel {
  [lang: string]: string;
}

interface Translation {
  language: string;
  translation: string;
}

const titleFormatter = (title: I18nLabel) => {
  if (!title) {
    return title;
  }
  return Object.keys(title).map((key) => ({
    language: key,
    translation: title[key],
  }));
};

const titleParser = (translations: Translation[]) => {
  if (!translations) {
    return translations;
  }
  return translations.reduce((prev, cur) => {
    prev[cur.language] = cur.translation;
    return prev;
  }, {} as I18nLabel);
};

async function prizeOutTransformation(resource: string, prize: any) {
  if (resource === 'prize') {
    if (prize) {
      if (prize.titleAsArray) {
        prize.title = titleParser(prize.titleAsArray);
        delete prize.titleAsArray;
      }
    }
  }
}

function prizeInTransformation(resource: string, prize: any): void {
  if (resource === 'prize') {
    if (prize) {
      if (prize.title) {
        prize.titleAsArray = titleFormatter(prize.title);
      }
    }
  }
}

export const prizeTransformations: DataTransformations<any> = {
  outCreateTransformation: prizeOutTransformation,
  outUpdateTransformation: prizeOutTransformation,
  inOneTransformation: prizeInTransformation,
};

const PrizeFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Race" source="room.event_title||starts" alwaysOn />
    <TextInput label="Title" source="title" />
  </Filter>
);

export const PrizeList = (props: any) => (
  <List {...props} filters={<PrizeFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="room.event_title" />
      <TextField source="title" />
      <TextField source="count" />
      <NumberField source="expectedParticipantPool" />
      <DateField source="createdAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const CommonFormPart = (props: any) => (
  <span>
    <ArrayInput source="titleAsArray" validate={[required()]}>
      <SimpleFormIterator>
        <SelectInput label="Language" source="language" choices={LOCALES} validate={[required()]} />
        <TextInput label="Translation" source="translation" fullWidth validate={[required()]}></TextInput>
      </SimpleFormIterator>
    </ArrayInput>
    <BackendFileUpload label="Image" source="image" accept="image/*" {...props} />
    <NumberInput source="count" fullWidth validate={[required()]} />
    <NumberInput
      helperText="Prizes are drawn in this window Must be set for openWindow races!! But can also be used to overwrite default of 100m"
      source="expectedParticipantPool"
      fullWidth
    />
    <TextInput
      source="allowedCountries"
      helperText={
        'Comma-seperated list of countries. Prizes are only given to users which have the country set to one defined here. Example: CH,FR,IT'
      }
      fullWidth
    />
    <TextInput source="notificationEmail" fullWidth />
  </span>
);

export const PrizeCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput perPage={200} sort={{ field: 'id', order: 'DESC' }} label="Race" source="roomId" reference="room" fullWidth>
        <SelectInput optionText={(record: any) => record.id + ' | ' + record.event_title + ' | ' + record.startDate} fullWidth />
      </ReferenceInput>
      <CommonFormPart {...props} />
    </SimpleForm>
  </Create>
);

export function getPrizeTitle(title: I18nLabel): string {
  if (!title) {
    return '';
  }
  return title.de || title.en || title.fr || title.it || title.sv || title.pt || title.ru || title.zh || JSON.stringify(title);
}

const PrizeEditTitle = ({ record }: any) => <span>{`${record.id} ${getPrizeTitle(record.title)}`}</span>;

export const PrizeEdit = (props: any) => (
  <Edit {...props} title={<PrizeEditTitle record />}>
    <SimpleForm redirect="list">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Edit>
);

export const PrizeShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Prize">
        <TextField source="id" />
        <TextField source="room.event_title" />
        <TextField source="title" />
        <ImageField source="image" />
        <TextField source="count" />
        <TextField source="notificationEmail" />
        <DateField source="createdAt" />
      </Tab>
      <Tab label="Winners">
        <ReferenceManyField pagination={<Pagination />} perPage={10} label="Winners" reference="prize-winner" target="prizeId">
          <Datagrid>
            <FormattedUserField source="user" />
            <BooleanField source="collected" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
