import React, { cloneElement } from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  Filter,
  List,
  NumberField,
  sanitizeListRestProps,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';

import badgeUsersExporter from './badge-users-exporter';

const BadgeUsersFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Badge ID" source="badge.id||$eq" alwaysOn />
    <TextInput label="User ID" source="user.id||$eq" alwaysOn />
    <DateInput label="Created At >=" source="createdAt||$gte" alwaysOn />
    <DateInput label="Created At <=" source="createdAt||$lte" alwaysOn />
  </Filter>
);

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
    </TopToolbar>
  );
};

export const BadgeUserList = (props: any) => (
  <List {...props} filters={<BadgeUsersFilter />} exporter={badgeUsersExporter} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="userId" />
      <TextField source="user.username" label="Username" />
      <NumberField source="badgeId" />
      <TextField source="badge.title" label="Badge" />
      <DateField source="createdAt" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const BadgeUserShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <NumberField source="userId" />
        <NumberField source="badgeId" />
        <TextField source="createdAt" />
        <TextField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
